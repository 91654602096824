<template>
  <div class="pageFootWrap wrap" :style="{background:(pageRouter=='/AppPage'?'#ffffff':''),}">
    <div class="pageFootBox box">
        <div class="pageFootLeft">
          <div>安徽倾行网络科技有限公司</div>
          <div @click="toSelect" style="cursor: pointer"> 皖ICP备19015837-1号 文化经营许可证：编号 皖网文【2021】1736-027号</div>
          <div style="display: flex;flex-wrap: wrap">  <span style="margin-right: 28px;margin-bottom: 18px">邮箱：admin@smxos.com</span>联系电话：0551-65510227</div>
          <div>地址：安徽省合肥市高新区云飞路与文曲路交口创新产业园二期G2栋901室</div>
          <div class="pageFootLeftIcon">
            <el-image style="width:50px;height: 50px;margin-right: 4%" @click="toWebTo(1)" :src="require('@/assets/icon1.png')" fit="contain"></el-image>
            <el-image style="width:50px;height: 50px;margin-right: 4%" @click="toWebTo(2)" :src="require('@/assets/icon2.png')" fit="contain"></el-image>
            <el-image style="width:50px;height: 50px;margin-right: 4%" @click="toWebTo(3)" :src="require('@/assets/icon3.png')" fit="contain"></el-image>
          </div>
        </div>
        <div class="pageFootRight">
          <el-image style="width: 150px;height: 150px;margin-right: 35px" class="pageFootRightLeftImage" :src="allInfo.code" fit="contain"></el-image>
          <div class="pageFootRightCenter">
            <el-image class="pageFootRightCenterImage" @click="toDownload('android')" style="width: 200px;margin-bottom: 15px;cursor: pointer;" :src="allInfo.Android" fit="contain"></el-image>
            <el-image class="pageFootRightCenterImage" @click="toDownload('ios')" style="width: 200px;cursor: pointer;" :src="allInfo.iOS" fit="contain"></el-image>
          </div>
        </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="280px">
      <img :src="webImg" style="width: 200px;margin:auto;display:block;height: auto;" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "pageFoot",
  data(){
    return{
      pageRouter:this.$router.currentRoute.fullPath,
      allInfo:this.$store.state.pageInfo.allPage,
      webImg:'',
      dialogVisible:false,
    }
  },
  methods:{
    toSelect(){
      window.open("https://beian.miit.gov.cn/")
      // location.href='http://www.beian.gov.cn/'
    },
    toWebTo(e){
      this.dialogVisible=true
      if (e==1){
          this.webImg=require('@/assets/dy.jpg')
      }else if(e==2){

        this.webImg=require('@/assets/wb.jpg')
      }else{

        this.webImg=require('@/assets/wx.jpg')
      }
    },
    toDownload(e){
      this.$store.dispatch('toDownload',e)
    },
  }
}
</script>

<style scoped lang="scss">
.pageFootWrap{
  background: #F7F7F7;
}
.pageFootBox{
  padding:18px 30px;
  font-size: 18px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .pageFootLeft{
    margin-right: 20px;
    div{
      margin-top: 20px;
    }
  }
  .pageFootRight{
    display: flex;
    margin-top: 30px;
    align-items: center;
  }
  .pageFootRightCenter{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
</style>