<template>
  <div class="pageHeadWrap">
    <div class="pageHead box">
      <div class="pageHeadLeft">
        <el-image style="width: 36px; height: 36px;margin-right: 18px" :src="require('@/assets/logo.png')" fit="contain"></el-image>
        <el-image style="width: 56px; height: 38px" :src="require('@/assets/r-logo'+(pageRouter=='/AppPage'?'-black':'')+'.png')" fit="contain"></el-image>
      </div>
      <div class="pageHeadRight" style="user-select:none;">
        <div class="phoneMenu">
          <i class="el-icon-s-fold" :style="{color:pageRouter=='/AppPage'?'#333333':'#ffffff'}"  @click="openMenu"></i>
          <el-drawer
              size="60%"
              destroy-on-close
              append-to-body
              style="z-index: 999999;user-select:none;"
              :visible.sync="miniMenu"
              direction="ttb">
            <div slot="title" class="miniMenuTitle">
              <el-image style="width: 112px; height: 76px" :src="require('@/assets/r-logo-black.png')" fit="contain"></el-image>
            </div>
            <div class="miniMenuWrap">
              <div class="miniMenuRow" :class="pageRouter=='/'?'active':''" @click="toPath('/')">首页</div>
              <div class="miniMenuRow" :class="pageRouter=='/AppPage'?'active':''" @click="toPath('/AppPage')">倾行App</div>
              <div class="miniMenuRow" :class="pageRouter=='/equipment'?'active':''"  @click="toPath('equipment')">智能设备</div>
              <div class="miniMenuRow" :class="pageRouter=='/about'?'active':''"  @click="toPath('about')">关于我们</div>
            </div>
          </el-drawer>
        </div>
        <div class="headMenuWrap" :style="{color:pageRouter=='/AppPage'?'#333333':'#ffffff'}">
          <div class="headMenuRow" :class="pageRouter=='/'?'active':''" @click="toPath('/')">首页</div>
          <div class="headMenuRow" :class="pageRouter=='/AppPage'?'active':''" @click="toPath('/AppPage')">倾行App</div>
          <div class="headMenuRow" :class="pageRouter=='/equipment'?'active':''"  @click="toPath('equipment')">智能设备</div>
          <div class="headMenuRow" :class="pageRouter=='/about'?'active':''"  @click="toPath('about')">关于我们</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "pageHead",
  data() {
    return {
      pageRouter:this.$router.currentRoute.fullPath,
      activeIndex: '1',
      activeIndex2: '1',
      miniMenu:false
    };
  },
  mounted() {
    console.log(this.$router.currentRoute.fullPath)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toPath(e){
      this.$router.push(e);
    },
    openMenu(){
      this.miniMenu=true
    }
  }
}
</script>
<style scoped lang="scss">
.pageHeadWrap{
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  z-index: 1000;
  min-width: 350px;
  padding: 0 30px;
  box-sizing: border-box;
}
.pageHead{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageHeadLeft{
  display: flex;
  align-items: center;
}
.pageHeadRight{
  display: flex;
  align-items: center;
  .phoneMenu{
    font-size: 40px;
    cursor: pointer;
    display: none;
    .el-icon-s-fold{
      color: #ffffff;
    }
  }
  .headMenuWrap{
    display: flex;
    align-items: center;
    color: #ffffff;
    .headMenuRow{
      margin-left: 48px;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      transition: all .3s;
      &:hover{
        color: #f8aa44;
      }
    }
    transition: all .3s;
    .active{
      color: #f8aa44;
    }
  }
}
.miniMenuTitle{
  display: flex;
  align-items: center;
  justify-content: center;
}
.miniMenuWrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  .miniMenuRow{
    display: flex;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    color: #666666;
  }
  .active{
    color: #f8aa44;
  }
}
</style>